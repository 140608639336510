import React from 'react';
import Button from '../components/Button';
import Card from '../components/Card';
import CustomerCard from '../components/CustomerCard';
import LabelText from '../components/LabelText';
import Layout from '../components/layout/Layout';
import SplitSection from '../components/SplitSection';
import StatsBox from '../components/StatsBox';
import customerData from '../data/customer-data';
import HeroImage from '../images/new-home-screenshot.png';
import SvgCharts from '../svg/SvgCharts';
import { Link } from "gatsby";
import narrative_construction from '../images/narrative_construction.png';
import except1 from '../images/except1.png';
import tizano from '../images/tizano.png';
import pm from '../images/pm.png';
import { Helmet } from "react-helmet"

export default () => (
<>
<Helmet title="VUE - Gallery" defer={false} />
<Layout>
<section id="features" className="py-20 lg:pb-40">
      <div className="container mx-auto text-center">
        <h2 className="text-3xl lg:text-5xl font-semibold">Gallery</h2>
        <p className="text-md md:text-md mt-6 font-light">
        The "Gallery" section is new to our website. We hope to build a strong VUE community of users and thinkers. We need your help in creating a stimulating gallery area, showcasing your uses of VUE. Let us know about your maps and stories, and we will post them on this page.
        </p>              
        <h2 className="text-3xl lg:text-5xl font-semibold">Featured Maps</h2>
        <div className="flex flex-col sm:flex-row sm:-mx-3 mt-12">
          <div className="flex-1 px-3">
            <Card className="mb-8">
              <p className="font-semibold text-xl">Narrative Construction</p>
              <p className="mt-4 mb-4">
                Patrick Szucs explains the use as a Tool for the <Link to="/narrative_construction">Construction of Narrative.</Link>
              </p>
              <img src={narrative_construction}/>
            </Card>
          </div>
          <div className="flex-1 px-3">
            <Card className="mb-8">
              <p className="font-semibold text-xl">VUE Maps at Except</p>
              <p className="mt-4 mb-4">
                Eva Gladek of Except Integrated Systainability uses VUE to create system maps, read more about how they use VUE here. To learn more about Except, visit http://www.except.nl.  
                <Link to="/except"> Learn more about how they use VUE.</Link>
              </p>
              <img src={except1}/>
            </Card>
          </div>          
        </div>
      </div>
      <div className="flex flex-col sm:flex-row sm:-mx-3 mt-12">
      <div className="flex-1 px-3">
            <Card className="mb-8">
              <p className="font-semibold text-xl">Masterworks</p>
              <p className="mt-4 mb-4">
                K. Bender has been using VUE maps to represent <a href="http://sites.google.com/site/venusiconography/home/connectivity-maps/tiziano-s-venus-with-the-musician-1">TIZIANO's Venus with the Musician</a>, (masterworks and their successors), and <a href="http://sites.google.com/site/venusiconography/home/connectivity-maps/watteau-s-embarquement-pour-cythere">WATTEAU's Embarquement pour Cythère</a> (masterworks, their predecessors and their successors)
              </p>
              <img src={tizano}/>
            </Card>
          </div>          
          <div className="flex-1 px-3">
            <Card className="mb-8">
              <p className="font-semibold text-xl">Project Management</p>
              <p className="mt-4 mb-4">
                Professor Rémi Bachelet, from l'Ecole Centrale de Lille in France, has been encouraging his students to use VUE to help them learn about Project Management. See examples of his students' work.                
              </p>
              <img src={pm}/>
            </Card>
          </div>          
      </div>
    </section>
 
</Layout>
</>
)